<template>
  <div class="contentBA top-padding">
    <top-home title="用户绑定" :className="['top-white']"></top-home>
    <div class="Head">
      <div class="left">
        <img
          src="../../../assets/PersonalCenter/PersonalCheck_img (1).png"
          alt
        />
      </div>
      <div class="right">
        <div class="right-text-zh">立即添加绑定账号</div>
        <div class="right-btn" @click="jumpFindTenant">立即添加</div>
      </div>
    </div>
    <div class="BA-bottom">
      <div
        class="list-item"
        :class="{ select_active: item.flag }"
        v-for="item in bindList"
        :key="item.id"
      >
        <div class="BA-bottom-t">
          <div class="top-title">
            <img
              src="../../../assets/home/company.png"
              style="
                width: 28px;
                height: 28px;
                background-color: #008dff;
                border-radius: 14px;
                box-sizing: border-box;
                padding: 4px;
                margin-right: 6px;
              "
            />
            <span>{{ item.tenant_name }}</span>
          </div>
          <div class="top-jb">
            <span style @click="poupDia(item)">解绑</span>
          </div>
        </div>
        <div class="content-box" @click="bindUserFun(item)">
          <ul>
            <li>
              姓名:
              <span>{{ item.name }}</span>
            </li>
            <li>
              甲方单位名称:
              <span>{{ item.jfdw_name }}</span>
            </li>
            <li>
              绑定时间:
              <span>{{ item.bdsj }}</span>
            </li>
            <!-- <li>
              维保时间:
              <span>{{item.qdsj && !item.qtsj? `${item.qdsj} 开始保养` : item.qdsj && item.qtsj? `${item.qtsj} 完成保养`:''}}</span>
            </li> -->
          </ul>
          <div class="box-image">
            <img
              src="../../../assets/home/nextarrow.png"
              @click="baningFun(item)"
            />
          </div>
        </div>
        <div class="item-success" v-if="item.flag"></div>
        <div class="item-icon" v-if="item.flag">
          <van-icon name="success" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getUnbindWxUser, getWxBindUserList } from '@/reuqest/api.js'
import { removeToken, removeRefreshToken } from '@/util/auth'
import config from '@/config'

export default {
  data() {
    return {
      showBbt: false,
      list: [],
      cbyPop: false,
      cbyForm: {
        username: '',
        password: ''
      }
    }
  },
  filters: {
    yytFilter(value) {
      return value.split(',').join(' | ')
    }
  },
  computed: {
    ...mapGetters(['wxLoginInfo', 'bindList', 'tenantId', 'token'])
  },
  created() {
    if (this.bindList && this.bindList.length < 1) {
      this.getBindData()
    }
  },
  methods: {
    ...mapMutations(['SET_WX_LOGIN_INFO', 'SET_IS_CUT', 'SET_BIND_LIST']),
    ...mapActions(['getBindList', 'clearLogin']),
    poupDia(item) {
      this.$dialog
        .confirm({
          title: '提示',
          message: '是否确定解绑?'
        })
        .then(() => {
          const loadingToast = this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration: 0
          })
          let key = 'jfyhWxId'
          let path = '/home'
          getUnbindWxUser({ id: item.id, openId: this.wxLoginInfo.openId })
            .then((res) => {
              if (item.flag && this.bindList.length != 1) {
                let id = ''
                this.bindList.forEach((bindItem) => {
                  if (!id && !bindItem.flag) {
                    id = bindItem.id
                  }
                })
                this.SET_WX_LOGIN_INFO({
                  ...this.wxLoginInfo,
                  [key]: id
                })
                this.SET_IS_CUT(true)
                loadingToast.clear()
                this.$router.push(path)
                //当前绑定
              } else if (this.bindList.length == 1) {
                //最后一个
                removeToken()
                removeRefreshToken()
                this.clearLogin(true)
                loadingToast.clear()
              } else {
                //正常解绑
                this.getBindList().then((res) => {
                  loadingToast.clear()
                  this.$toast.success('解除绑定成功~')
                })
              }
            })
            .catch(() => {
              loadingToast.clear()
            })
        })
        .catch(() => {
          // on cancel
        })
    },
    jumpFindTenant() {
      this.$router.push('/FindTenant')
    },
    bindUserFun(item) {
      let key = 'jfyhWxId'
      let path = '/home'
      if (item.flag) {
        this.$router.push(path)
      } else {
        this.$dialog
          .confirm({
            title: '提示',
            message: '是否切换到当前用户?'
          })
          .then(() => {
            this.SET_WX_LOGIN_INFO({
              ...this.wxLoginInfo,
              [key]: item.id
            })
            this.SET_IS_CUT(true)
            this.$router.push(path)
          })
          .catch(() => {})
      }
    },
    async getBindData() {
      let res = await getWxBindUserList({
        openId: this.wxLoginInfo.openId
      })
      this.SET_BIND_LIST(res.data)
    }
  }
}
</script>

<style lang="less" scoped>
.BA-bottom {
  margin: 10px;
  border: 1px soild rgb(245, 245, 245);
  li {
    padding: 5px 0;
  }
}
.BA-bottom-t {
  height: 30px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgb(245, 245, 245);
  .top-title {
    flex: 1;
    font-size: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    span {
      width: 85%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .top-jb {
    border: 1px solid rgb(0, 122, 255);
    border-radius: 10%;
    font-size: 14px;
    span {
      display: block;
      color: rgb(0, 122, 255);
      padding: 2px 10px;
    }
  }
  img {
    vertical-align: middle;
    padding-right: 5px;
    width: 25px;
  }
}
.content-box {
  position: relative;
  padding: 10px 0;
  font-size: 15px;
  padding-right: 40px;
  ul {
    text-align: left;
  }
  .box-image {
    position: absolute;
    top: 30%;
    right: 10px;
  }
  img {
    width: 18px;
    float: right;
  }
}
.contentBA {
  background-color: rgb(245, 245, 245);
  min-height: 100vh;
}
.Head {
  background-color: rgb(43, 77, 225);
  display: flex;
  padding: 10px 0;
  .left {
    width: 50%;
    img {
      width: 100%;
    }
  }
}

.list-item {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  &.select_active {
    border: 1px solid #fe9631;
  }
  .item-success {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 35px solid #fe9631;
    border-right: 35px solid transparent;
  }
  .item-icon {
    position: absolute;
    left: 2px;
    top: 2px;
    color: #fff;
  }
}
.contentBA {
  .right {
    width: 60%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .right-text-an {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .right-text-zh {
      font-size: 14px;
      padding-bottom: 10px;
    }
    .right-btn {
      font-size: 16px;
      background-color: #fd9e16;
      padding: 8px 20px;
      border-radius: 5px;
      text-align: center;
    }
    .sqbz-btn {
      background-color: #fff;
      color: #409eff;
      margin-bottom: 10px;
    }
  }
}
.cby-form-box {
  padding: 15px 0;
}
</style>
