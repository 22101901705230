import { render, staticRenderFns } from "./BandingAccount.vue?vue&type=template&id=13dcaa05&scoped=true&"
import script from "./BandingAccount.vue?vue&type=script&lang=js&"
export * from "./BandingAccount.vue?vue&type=script&lang=js&"
import style0 from "./BandingAccount.vue?vue&type=style&index=0&id=13dcaa05&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13dcaa05",
  null
  
)

export default component.exports